import {
  EVENT_TYPE_IN_PERSON,
  EVENT_TYPE_LIVE
} from '@/pages/portal/events/constants';

export const getEventTypeLabel = (t, eventType, locationName) => {
  switch (eventType) {
    case EVENT_TYPE_LIVE:
      return t('online');
    case EVENT_TYPE_IN_PERSON:
      return locationName ? locationName : t('in-person');
    default:
      return eventType;
  }
};

export const getEventTicketText = (t, eventAttendeeSlotsLeft) => {
  if (eventAttendeeSlotsLeft < 10) {
    return t('number-tickets-left', {
      ticketsLeft: eventAttendeeSlotsLeft
    });
  } else {
    // This will handle the case where eventAttendeeSlotsLeft is exactly 10
    return t('limited-slots');
  }
};
